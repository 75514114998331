<template>
  <div class="height">
      <div class="device-swipe">
          <van-swipe class="my-swipe" :autoplay="code.images_video.swipe_interval*1000" indicator-color="white" v-if="code && code.images_video.swipe_type==1">
            <van-swipe-item v-for="(images,index) in code.images_video.images" v-bind:key="index">
              <img :src="images.url"/>
            </van-swipe-item>
          </van-swipe>
          <video class="dev-swipe" loop muted autoplay controls v-if="code && code.images_video.swipe_type==2">
            <source
              :src="code.images_video.video"
              type="video/mp4"
            />
          </video>
      </div>
      <div class="device-main" @click="useFun">开始使用</div>
      <div class="flex flex-x">
          <div class="flex">
              <div class="device-num" v-if="code">设备编号：<span>{{code.info.equipment_code}}</span></div>
              <div class="device-num device-history" v-if="code" @click="historyLoginFun">历史记录</div>
          </div>
      </div>
      
      <div v-if="show">
        <vue-popup url="confirmAssess" :showFun="showFun" audio="welcome" />
      </div>
      <div v-if="history">
          <vue-popup :showFun="noLoginFun" url="reportList" :query="{return:true}" :login="true" text="搜索报告" />
      </div>
  </div>
</template>

<script>
import { mapActions , mapState , mapMutations } from 'vuex';
import vuePopup from "@/components/popup";
import { audioPaly } from "@/utils";
import { welcome  } from "@/utils/audio";

export default {
  name: 'device',
  data(){
    return{
      show: false
    }
  },
  components:{
    vuePopup
  },
  created:function(){
      this.deviceFun(this.axiosFun);
      sessionStorage.removeItem('token');
      if(sessionStorage.device=='android'){
          //安卓使用H5摄像头
          if(this.$route.query.edtion && this.$route.query.edtion<2){
              sessionStorage.setItem("camera", "true");
          }
      }
  },
  destroyed: function () {
      this.changeFun({
        key:'history',
        value:false
      })
  },
  computed:{
    ...mapState('device',['code']),
    ...mapState ("report", ['history']),
  },
  methods:{
      //获取数据
      axiosFun(mac){
        this.axiosData({
          data:{mac:mac},
          _this:this
        });
      },
      //判断设备环境
      deviceFun(axiosFun){
        let device = sessionStorage.getItem('device');
        if(device=='windows'){
          window.ipcRenderer.send("getMacAddress");
          window.ipcRenderer.on("setMacAddress", (e, msg) => {
            axiosFun(msg);
          });
        }else if(device=='android'||this.$route.query.mac){
          axiosFun(this.$route.query.mac);
        }
      },
      useFun(){
        if(this.$store.state.device.code){
          if(this.$store.state.device.code.info.testing_type==2){
            this.show = true;
          }else{
            //语音
            audioPaly.play(welcome);
            this.$router.push({
                name:'confirmAssess'
            });
          }
        }
      },
      //退出登录
      noLoginFun(){
          this.changeFun({
              key:'history',
              value:false
          })
      },
      historyLoginFun(){
          if(sessionStorage.token){
            this.$router.push({
                name:'reportList'
            });
          }else{
            //登录
            this.changeFun({
                key:'history',
                value:true
            })
          }
      },
      showFun(){
        this.show = false;
      },
      ...mapMutations ("report", ["changeFun"]),
      ...mapActions('device',['axiosData']),
  }
}
</script>

<style lang="scss" scoped> 
    @import './device'; 
</style>